@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600&display=swap");

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@font-face {
  font-family: "Apercu";
  src: local("Apercu"), url(./fonts/Apercu/apercu-regular.woff) format("woff");
}

@font-face {
  font-family: "Domaine Display";
  src: local("Domaine Display"),
    url(./fonts/Domaine/domaine-display-web-regular.woff) format("woff");
}

html,
body {
  height: 100%;
}

#root {
  height: calc(100% - 1.5rem);
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #b8c7b8;
  outline: 1px solid slategrey;
}

body {
  letter-spacing: 0 !important;
}

a,
button {
  cursor: pointer;
}

/* Carousel Styling */

.yz-carousel .slick-prev::before,
.yz-carousel .slick-next::before {
  content: " ";
  width: 12px;
  height: 21px;
  display: block;
  opacity: 1 !important;
}

.yz-carousel .slick-prev,
.yz-carousel .slick-next {
  top: 45%;
}

.yz-carousel .slick-prev::before {
  background: url("https://storage.googleapis.com/yardzen-public-assets/carousel-angle-left.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.yz-carousel .slick-next::before {
  background: url("https://storage.googleapis.com/yardzen-public-assets/carousel-angle-right.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.yz-carousel .slick-dots li button::before {
  width: 7px;
  height: 7px;
  content: " ";
  opacity: 1 !important;
  border-radius: 7px;
  border: 1px solid #000;
}

.yz-carousel .slick-dots li.slick-active button::before {
  background: #000;
}
